import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, lang, description } = headData;

  function initializeGA() {
    // load gtag.js:  https://developers.google.com/analytics/devguides/collection/gtagjs
    const script1 = document.createElement('script');
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GA_MEASUREMENT_ID}`;
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    // プレーンテキスト、グローバルな window.gtag 関数を定義するため
    script2.text = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.GA_MEASUREMENT_ID}');`;
    document.body.appendChild(script2);
  }

  useEffect(() => {
    initializeGA();
    window.setTimeout(() => {
      window.gtag('config', `'${process.env.GA_MEASUREMENT_ID}'`, {
        page_path: '/',
      });
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Gatsby Simplefolio'}</title>
        <html lang={lang || 'jp'} />
        <meta name="description" content={description || 'Gatsby Simplefolio'} />
      </Helmet>
      <App />
    </>
  );
};
