import React, { useContext, useState } from 'react';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import { Container, Modal, Form, Button, Alert } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn } = contact;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formuser, setFormUser] = useState('');
  const [formemail, setFormEmail] = useState('');
  const [formcontent, setFormContent] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    setValidated(false);
  };
  const closeModal = () => {
    setIsOpen(false);
    setFormUser('');
    setFormEmail('');
    setFormContent('');
    setAlertVisible(false);
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    // event.stopPropagation();
    if (form.checkValidity() === true) {
      // Set alert
      setAlertVariant('dark');
      setAlertMessage('お問い合わせ内容送信中...');
      setAlertVisible(true);
      const params = new URLSearchParams();
      params.append('form-name', 'contact');
      params.append('formname', formuser);
      params.append('formemail', formemail);
      params.append('formcontent', formcontent);
      axios
        .post('/', params)
        .then(() => {
          setAlertVariant('success');
          setAlertMessage('送信に成功しました');
          setTimeout(closeModal, 1500);
        })
        .catch((err) => {
          setAlertVariant('danger');
          setAlertMessage(`送信に失敗しました: ${err}`);
        });
    }
    setValidated(true);
  };

  return (
    <section id="contact">
      <Container>
        <Title title="Contact" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">{cta || 'Would you like to work with me? Awesome!'}</p>
            <button type="button" className="cta-btn cta-btn--resume" onClick={openModal}>
              {btn || "Let's Talk"}
            </button>
          </div>
        </Fade>
      </Container>
      <div style={{ display: 'none' }}>
        <form name="contact" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <input type="text" name="formname" defaultValue={formuser} />
          <input type="text" name="formemail" defaultValue={formemail} />
          <input type="text" name="formcontent" defaultValue={formcontent} />
        </form>
      </div>
      <Modal size="lg" show={modalIsOpen} onHide={() => closeModal()} dialogClassName="modal-wrapper" aria-labelledby="modal-dialog">
        <Modal.Header closeButton bsPrefix="modal-header">
          <Modal.Title id="modal-dialog">お問い合わせフォーム</Modal.Title>
        </Modal.Header>
        {alertVisible ? <Alert variant={alertVariant}>{alertMessage}</Alert> : <></>}
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>お名前</Form.Label>
              <Form.Control size="lg" type="text" name="name" value={formuser} placeholder="お名前" required onChange={(e) => setFormUser(e.target.value)} />
              <Form.Control.Feedback type="invalid">お名前は必須入力です</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput2">
              <Form.Label>連絡先(email)</Form.Label>
              <Form.Control size="lg" type="email" name="email" value={formemail} placeholder="name@example.com" required onChange={(e) => setFormEmail(e.target.value)} />
              <Form.Control.Feedback type="invalid">emailは必須入力です</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>お問い合わせ内容</Form.Label>
              <Form.Control size="lg" as="textarea" name="content" value={formcontent} rows={10} required onChange={(e) => setFormContent(e.target.value)} />
              <Form.Control.Feedback type="invalid">お問い合わせ内容は必須入力です</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" size="lg" type="submit">
              送信する
            </Button>{' '}
            <Button variant="outline-secondary" size="lg" onClick={closeModal}>
              閉じる
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Contact;
